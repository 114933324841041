import "../css/style.scss";
import { Link, useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

function FormExternal() {
  const [formData, setFormData] = useState({
    nik: "",
    nama_lengkap: "",
    no_hp: "",
    gender: "",
    status_pernikahan: "",
    email: "",
    tempat_lahir: "",
    tgl_lahir: "",
    agama: "",
    alamat: "",
    rt: "",
    rw: "",
    provinsi: "",
    kota: "",
    kecamatan: "",
    desa: "",
    kodepos: "",
    pendidikan_terakhir: "",
    nama_sekolah: "",
    brand: "",
    fk_user: "",
    photo: "-",
    cv: "-",
    nama_depan: "-",
    ipk: "0",
    sosial_media: "-",
    referensi_job: "-",
    ekspektasi_gaji: "",
    tahun_masuk: "0",
    tahun_lulus: "0",
    status_rumah: "-",
    hubungan_keluarga: "Tidak Ada",
    sosial_media: "-",
    no_telp: "-",
    vendor: "-",
    brand: "-",
    jurusan: "-",
  });

  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [kecamatans, setKecamatans] = useState([]);
  const [desas, setDesas] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [brands, setBrands] = useState([]);
  const [kota, setKota] = useState([]);

  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedKecamatan, setSelectedKecamatan] = useState("");
  const [selectedDesa, setSelectedDesa] = useState("");
  const [selectedVendor, setSelectedVendor] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [filteredBrands, setFilteredBrands] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const history = useHistory();
  const token = localStorage.getItem("token");
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    // const token = localStorage.getItem("token");

    if (!token) {
      console.log("Token tidak tersedia atau kosong");
      Swal.fire({
        icon: 'warning',
        title: 'Peringatan',
        text: 'Anda perlu login untuk melanjutkan.',
        confirmButtonText: 'OK'
      }).then((result) => {
          if (result.isConfirmed) {
              window.location.href = '/login';
          }
      });
    } else {
      console.log("Token: ", token);
    }

    const parts = token.split("|");
    if (parts.length === 2) {
      const id_user = parts[0];
      setFormData((prevFormData) => ({
        ...prevFormData,
        fk_user: id_user,
      }));
      console.log(`ID User yang login: ${id_user}`);
    } else {
      console.log("Format token tidak sesuai");
    }

    axios
      .get("https://hris.anyargroup.co.id/api/provinsi", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setProvinces(response.data.data || []);
      })
      .catch((error) => {
        console.error("There was an error fetching the provinces!", error);
      });

    axios
      .get("https://hris.anyargroup.co.id/api/execsp", {
        headers: {  
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const data = response.data;
        setVendors([...new Set(data.map((item) => item.VendorName))]);
        setBrands(data);
      })
      .catch((error) => {
        console.error("There was an error fetching the brands!", error);
      });

    axios
      .get("https://hris.anyargroup.co.id/api/kota", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setKota(response.data.data || []);
      })
      .catch((error) => {
        console.error("There was an error fetching the kota!", error);
      });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setInputValue(value);

    const filtered = kota.filter((option) =>
      option.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const handleOptionClick = (option) => {
    setInputValue(option.name);
    setFormData({
      ...formData,
      tempat_lahir: option.name,
    });
    setFilteredOptions([]);
  };

  const handleProvinceChange = (event) => {
    const provinceId = event.target.value;
    const provinceName = event.target.options[event.target.selectedIndex].text;
    console.log("provinsi yang dipilih : ", provinceName);
    setSelectedProvince(provinceId);
    setFormData({
      ...formData,
      provinsi: provinceName,
    });
    axios
      .get(`https://hris.anyargroup.co.id/api/kota?id_provinsi=${provinceId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCities(response.data.data || []);
        setKecamatans([]);
        setDesas([]);
      })
      .catch((error) => {
        console.error("There was an error fetching the cities!", error);
      });
  };

  const handleCityChange = (event) => {
    const cityId = event.target.value;
    const cityName = event.target.options[event.target.selectedIndex].text;
    console.log("kota yang dipilih : ", cityName);
    setSelectedCity(cityId);
    setFormData({
      ...formData,
      kota: cityName,
    });
    axios
      .get(`https://hris.anyargroup.co.id/api/kecamatan?id_kota=${cityId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setKecamatans(response.data.data || []);
        setDesas([]);
      })
      .catch((error) => {
        console.error("There was an error fetching the kecamatans!", error);
      });
  };

  const handleKecamatanChange = (event) => {
    const kecamatanId = event.target.value;
    const kecmatanName = event.target.options[event.target.selectedIndex].text;
    setSelectedKecamatan(kecamatanId);
    setFormData({
      ...formData,
      kecamatan: kecmatanName,
    });
    axios
      .get(`https://hris.anyargroup.co.id/api/desa?id_kecamatan=${kecamatanId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDesas(response.data.data || []);
      })
      .catch((error) => {
        console.error("There was an error fetching the desas!", error);
      });
  };

  const handleDesaChange = (event) => {
    const desaId = event.target.value;
    const desaName = event.target.options[event.target.selectedIndex].text;
    setSelectedDesa(desaId);
    setFormData({
      ...formData,
      desa: desaName,
    });
  };

  const handleVendorChange = (event) => {
    const vendorName = event.target.value;
    setSelectedVendor(vendorName);

    // Filter vendors for autocomplete suggestions
    const filteredSuggestions = vendors.filter((vendor) =>
      vendor.toLowerCase().includes(vendorName.toLowerCase())
    );

    setSuggestions(filteredSuggestions);

    // If vendor is selected exactly, update formData
    if (vendors.includes(vendorName)) {
      const filtered = brands.filter(
        (brand) => brand.VendorName === vendorName
      );

      const onlyNoManufacturerOrBlank = filtered.every(
        (brand) =>
          brand.firmname === "- No Manufacturer -" ||
          brand.firmname.trim() === ""
      );

      setFilteredBrands(filtered);
      setFormData({
        ...formData,
        vendor: vendorName,
        brand: onlyNoManufacturerOrBlank ? filtered[0]?.firmname : "",
      });
      setSelectedBrand(onlyNoManufacturerOrBlank ? filtered[0]?.firmname : "");
    }
  };

  const getStatusOptions = () => {
    const options = [
      { value: "", label: "Pilih Status..." },
      { value: "Menikah", label: "Menikah" },
      { value: "Belum Menikah", label: "Belum Menikah" },
    ];

    if (formData.gender === "Laki-laki") {
      options.push({ value: "Duda", label: "Duda" });
    } else if (formData.gender === "Perempuan") {
      options.push({ value: "Janda", label: "Janda" });
    }

    return options;
  };

  const handleVendorSelect = (vendorName) => {
    setSelectedVendor(vendorName);
    setSuggestions([]);

    const filtered = brands.filter((brand) => brand.VendorName === vendorName);

    const onlyNoManufacturerOrBlank = filtered.every(
      (brand) =>
        brand.firmname === "- No Manufacturer -" || brand.firmname.trim() === ""
    );

    setFilteredBrands(filtered);
    setFormData({
      ...formData,
      vendor: vendorName,
      brand: onlyNoManufacturerOrBlank ? filtered[0]?.firmname : "",
    });
    setSelectedBrand(onlyNoManufacturerOrBlank ? filtered[0]?.firmname : "");
  };

  const handleBrandChange = (event) => {
    const brandName = event.target.value;
    setSelectedBrand(brandName);
    setFormData({
      ...formData,
      brand: brandName,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);

    // Ensure no fields are null or undefined before submission
    const requiredFields = [
      "nik",
      "nama_lengkap",
      "no_hp",
      "gender",
      "status_pernikahan",
      "email",
      "tempat_lahir",
      "tgl_lahir",
      "agama",
      "alamat",
      "rt",
      "rw",
      "provinsi",
      "kota",
      "kecamatan",
      "desa",
      "kodepos",
      "pendidikan_terakhir",
      "nama_sekolah",
      "brand",
      "fk_user",
      "photo",
      "cv",
    ];

    for (const field of requiredFields) {
      if (!formData[field]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Kolom ${field} tidak boleh kosong.`,
        });
        return;
      }
    }
    axios
      .post("https://hris.anyargroup.co.id/api/pelamarexternal", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        Swal.fire({
          icon: "success",
          title: "Apply Job Success",
          showConfirmButton: false,
          timer: 3500,
        }).then(() => {
          history.push("/vacancy");
        });
      })
      .catch((error) => {
        console.error("There was an error submitting the form!", error);
      });
  };

  const today = new Date();
  const maxDate = new Date(today.setFullYear(today.getFullYear() - 18))
    .toISOString()
    .split("T")[0];

  return (
    <div className="content container">
      <div className="row form-row">
        <form onSubmit={handleSubmit}>
          <div className="col-12 form-col">
            <a href="/vacancy" class="btn btn-primary mb-3">
              Kembali
            </a>
            <h5 class="card-title mb-3">
              Formulir Pendafataran Karyawan Eksternal
            </h5>
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="mb-2">
                      <label for="FullName" className="col-form-label">
                        NIK<span className="wajib">*</span>
                      </label>
                      <div className="col-12 col-form-input">
                        <input
                          type="number"
                          name="nik"
                          placeholder="NIK..."
                          className="form-control form-control-sm input"
                          value={formData.nik}
                          onChange={handleChange}
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="mb-2">
                      <label for="FullName" className="col-form-label">
                        Nama Lengkap<span className="wajib">*</span>
                      </label>
                      <div className="col-12 col-form-input">
                        <input
                          type="text"
                          name="nama_lengkap"
                          placeholder="Nama Lengkap..."
                          className="form-control form-control-sm input"
                          value={formData.nama_lengkap}
                          onChange={handleChange}
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="mb-2">
                      <label for="FullName" className="col-form-label">
                        Nama Depan<span className="wajib">*</span>
                      </label>
                      <div className="col-12 col-form-input">
                        <input
                          type="text"
                          placeholder="Nama Depan..."
                          className="form-control form-control-sm input"
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="FullName" className="col-form-label">
                      Jenis Kelamin<span className="wajib">*</span>
                    </label>
                    <div className="col-12 col-form-input">
                      <select
                        class="form-select"
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                      >
                        <option selected>Pilih Gender...</option>
                        <option value="Laki-laki">Laki-laki</option>
                        <option value="Perempuan">Perempuan</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="FullName" className="col-form-label">
                      Status<span className="wajib">*</span>
                    </label>
                    <div className="col-12 col-form-input">
                      <select
                        class="form-select"
                        name="status_pernikahan"
                        value={formData.status_pernikahan}
                        onChange={handleChange}
                      >
                        {getStatusOptions().map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div class="mb-2">
                      <label for="FullName" className="col-form-label">
                        Email<span className="wajib">*</span>
                      </label>
                      <div className="col-12 col-form-input">
                        <input
                          type="email"
                          name="email"
                          placeholder="example@email.com"
                          value={formData.email}
                          onChange={handleChange}
                          className="form-control form-control-sm input"
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div class="mb-2">
                      <label for="FullName" className="col-form-label">
                        Nomor HP<span className="wajib">*</span>
                      </label>
                      <div className="col-12 col-form-input">
                        <input
                          type="number"
                          name="no_hp"
                          value={formData.no_hp}
                          onChange={handleChange}
                          placeholder="Masukkan nomor hp..."
                          className="form-control form-control-sm input"
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="mb-2">
                      <label for="FullName" className="col-form-label">
                        Tempat Lahir<span className="wajib">*</span>
                      </label>
                      <div className="col-12 col-form-input">
                        <input
                          type="text"
                          name="tempat_lahir"
                          value={inputValue}
                          onChange={handleInputChange}
                          placeholder="Tempat Lahir..."
                          className="form-control form-control-sm input"
                        />
                        {filteredOptions.length > 0 && (
                          <ul className="autocomplete-options">
                            {filteredOptions.map((option) => (
                              <li
                                key={option.id}
                                onClick={() => handleOptionClick(option)}
                              >
                                {option.name}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="mb-2">
                      <label for="FullName" className="col-form-label">
                        Tanggal Lahir<span className="wajib">*</span>
                      </label>
                      <div className="col-12 col-form-input">
                        <input
                          type="date"
                          name="tgl_lahir"
                          className="form-control form-control-sm input"
                          max={maxDate} // Set max date to the calculated max date
                          value={formData.tgl_lahir}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <label for="FullName" className="col-form-label">
                      Agama<span className="wajib">*</span>
                    </label>
                    <div className="col-12 col-form-input mb-2">
                      <select
                        className="form-select"
                        name="agama"
                        value={formData.agama}
                        onChange={handleChange}
                        required
                      >
                        <option selected>Pilih Agama...</option>
                        <option value="Islam">Islam</option>
                        <option value="Protestan">Protestan</option>
                        <option value="Katolik">Katolik</option>
                        <option value="Hindu">Hindu</option>
                        <option value="Buddha">Buddha</option>
                        <option value="Konghucu">Konghucu</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                    <label className="col-form-label">
                      Alamat Domisili<span className="wajib">*</span>
                    </label>
                    <div className="col-12 col-form-input mb-2">
                      <textarea
                        placeholder="Masukkan alamat..."
                        name="alamat"
                        className="form-control form-control-sm input"
                        value={formData.alamat}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-xs-6">
                    <label className="col-form-label">
                      RT<span className="wajib">*</span>
                    </label>
                    <input
                      type="number"
                      name="rt"
                      placeholder="Masukkan RT..."
                      value={formData.rt}
                      onChange={handleChange}
                      className="form-control form-control-sm input"
                    ></input>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-xs-6">
                    <label className="col-form-label">
                      RW<span className="wajib">*</span>
                    </label>
                    <input
                      type="number"
                      name="rw"
                      placeholder="Masukkan RW..."
                      value={formData.rw}
                      onChange={handleChange}
                      className="form-control form-control-sm input"
                    ></input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12">
                    <label className="col-form-label">
                      Provinsi Domisili<span className="wajib">*</span>
                    </label>
                    <div className="col-12 col-form-input mb-2">
                      <select
                        className="form-select"
                        name="provinsi"
                        value={selectedProvince}
                        onChange={handleProvinceChange}
                      >
                        <option value="">Pilih Provinsi...</option>
                        {provinces.map((province) => (
                          <option key={province.id} value={province.id}>
                            {province.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12">
                    <label className="col-form-label">
                      Kota / Kabupaten Domisili<span className="wajib">*</span>
                    </label>
                    <div className="col-12 col-form-input mb-2">
                      <select
                        className="form-select"
                        name="kota"
                        value={selectedCity}
                        onChange={handleCityChange}
                      >
                        <option value="">Pilih Kota/Kabupaten...</option>
                        {cities.map((city) => (
                          <option key={city.id} value={city.id}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12">
                    <label className="col-form-label">
                      Kode Pos<span className="wajib">*</span>
                    </label>
                    <div className="col-12 col-form-input mb-2">
                      <input
                        type="number"
                        placeholder="Masukkan Kode Pos..."
                        name="kodepos"
                        value={formData.kodepos}
                        onChange={handleChange}
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                  <div class="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12">
                    <label className="col-form-label">
                      Kecamatan Domisili<span className="wajib">*</span>
                    </label>
                    <div className="col-12 col-form-input mb-2">
                      <select
                        className="form-select"
                        value={selectedKecamatan}
                        onChange={handleKecamatanChange}
                        name="kecamatan"
                      >
                        <option value="">Pilih Kecamatan...</option>
                        {kecamatans.map((kecamatan) => (
                          <option key={kecamatan.id} value={kecamatan.id}>
                            {kecamatan.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12">
                    <label className="col-form-label">
                      Kelurahan / Desa Domisili<span className="wajib">*</span>
                    </label>
                    <div className="col-12 col-form-input mb-2">
                      <select
                        className="form-select"
                        value={selectedDesa}
                        onChange={handleDesaChange}
                        name="desa"
                      >
                        <option value="">Pilih Kelurahan/Desa...</option>
                        {desas.map((desa) => (
                          <option key={desa.id} value={desa.id}>
                            {desa.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <label for="FullName" className="col-form-label">
                      Pendidikan Terakhir<span className="wajib">*</span>
                    </label>
                    <div className="col-12 col-form-input mb-2">
                      <select
                        class="form-select"
                        name="pendidikan_terakhir"
                        value={formData.pendidikan_terakhir}
                        onChange={handleChange}
                      >
                        <option selected>Pilih Pendidikan Terakhir...</option>
                        <option value="SD">SD/Sederajat</option>
                        <option value="SMP">SMP/Sederajat</option>
                        <option value="SMA">SMA/Sederajat</option>
                        <option value="Diploma 1">Diploma 1</option>
                        <option value="Diploma 2">Diploma 2</option>
                        <option value="Diploma 3">Diploma 3</option>
                        <option value="Diploma 4">Diploma 4</option>
                        <option value="Strata 1">Strata 1</option>
                        <option value="Strata 2">Strata 2</option>
                        <option value="Strata 3">Strata 3</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <label className="col-form-label">
                      Nama Institusi<span className="wajib">*</span>
                    </label>
                    <div className="col-12 col-form-input mb-2">
                      <input
                        type="text"
                        placeholder="Nama Institusi..."
                        name="nama_sekolah"
                        value={formData.nama_sekolah}
                        onChange={handleChange}
                        className="form-control form-control-sm input"
                      ></input>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <label className="col-form-label">
                      Vendor<span className="wajib">*</span>
                    </label>
                    <div className="col-12 col-form-input mb-3">
                    <input
                        type="text"
                        className="form-control"
                        name="vendor"
                        value={selectedVendor}
                        onChange={handleVendorChange}
                        onBlur={() => setTimeout(() => setSuggestions([]), 100)} // hide suggestions after blur
                        placeholder="Pilih Vendor..."
                        required
                      />
                      {suggestions.length > 0 && (
                        <ul className="autocomplete-options">
                          {suggestions.map((suggestion, index) => (
                            <li
                              key={index}
                              onMouseDown={() => handleVendorSelect(suggestion)} // Use onMouseDown instead of onClick
                            >
                              {suggestion}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <label className="col-form-label">
                      Brand yang Dilamar<span className="wajib">*</span>
                    </label>
                    <div className="col-12 col-form-input mb-3">
                      <select
                        className="form-select"
                        name="brand"
                        value={selectedBrand}
                        onChange={handleBrandChange}
                      >
                        <option selected value="-">
                          Pilih Brand...
                        </option>
                        {filteredBrands.map((brand, index) => (
                          <option key={index} value={brand.firmname}>
                            {brand.firmname}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <input type="hidden" name="photo" value="aaaa"></input>
                <input type="hidden" name="cv" value="-"></input>
                <input type="hidden" name="no_telp" value="-"></input>
                <input type="hidden" name="status_rumah" value="-"></input>
                <input type="hidden" name="jurusan" value="-"></input>
                <input type="hidden" name="tahun_masuk" value="-"></input>
                <input type="hidden" name="tahun_lulus" value="-"></input>
                <input type="hidden" name="ipk" value="-"></input>
                <input
                  type="hidden"
                  name="hubungan_keluarga"
                  value="Tidak Ada"
                ></input>
                <input
                  type="hidden"
                  name="fk_user"
                  value={formData.fk_user}
                ></input>
                <input type="hidden" name="sosial_media" value="-"></input>
                <input type="hidden" name="referensi_job" value="-"></input>
                <input type="hidden" name="ekspektasi_gaji" value="123"></input>
                <input type="hidden" name="no_telp" value="-"></input>

                <button type="submit" className="btn btn-primary">
                  <span className="inputdata">Submit</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FormExternal;
